import React, { useState } from 'react';
import './AuthForm.css';
import TextInput from '../../../components/TextInput';
import SmallTextButton from '../../../components/Buttons/SmallTextButton';
import LargeButton from '../../../components/Buttons/LargeButton';
import LargeTextButton from '../../../components/Buttons/LargeTextButton';
import MoneyportLogo from '../../../components/Icons/MoneyportLogo';
import { useContext } from 'react';
import { AuthContext } from '../../../context/context';
import { useLocation, useNavigate } from 'react-router-dom';
import { RECOVERY_ROUTE, REG_ROUTE } from '../../../utils/consts';
import { ReactComponent as Logo } from '../../../assets/logo/logo.svg';

const AuthForm = ({ className }) => {
  const { loading, onLogin } = useContext(AuthContext);

  const [email, setEmail] = useState('neizbejno@yandex.ru');
  const [password, setPassword] = useState('183CxL3c');
  const [errors, setErrors] = useState(null);

  const handleLogin = async () => {
    const { errors } = await onLogin({ email, password });
    setErrors(errors);
  };

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={className}>
      <Logo className="logo" />
      <form>
        <TextInput
          value={email}
          errors={errors?.email}
          onClick={() => setErrors(null)}
          placeholder={'E-mail'}
          type="text"
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px',
          }}
        >
          <TextInput
            value={password}
            errors={errors?.password || errors?.error}
            onClick={() => setErrors(null)}
            placeholder={'Пароль'}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />

          <SmallTextButton
            value={'Забыли пароль?'}
            onClick={() => navigate({ pathname: RECOVERY_ROUTE, search: location.search })}
          />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '12px',
            alignSelf: 'stretch',
          }}
        >
          <LargeButton
            text={'Авторизация'}
            variant="standart"
            onClick={() => handleLogin()}
            loading={loading}
          />

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '5px' }}>Еще нет аккаунта?</span>
            <LargeTextButton
              value={'Зарегистрироваться'}
              onClick={() => navigate({ pathname: REG_ROUTE, search: location.search })}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AuthForm;
