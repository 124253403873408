import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const authBase = axios.create({
  baseURL: BASE_URL,
});

authBase.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token'); // Assuming you store the token in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authBase.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = sessionStorage.getItem('refresh');
        const response = await axios.post(`${BASE_URL}/refresh`, {
          refresh_token: refreshToken,
        });
        const { access_token, refresh_token } = response.data;

        sessionStorage.setItem('token', access_token);
        sessionStorage.setItem('refresh', refresh_token);

        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return axios(originalRequest);
      } catch (error) {}
    }

    return Promise.reject(error);
  }
);
